import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../styles/media-query-template';
import { Link } from 'gatsby';

const SectionSubheading = styled.div`
  margin: 40px 0px;
  font-size: 1.125rem;
  font-weight: bold;
  ${media.medium`
    font-size: 2.1875rem;
  `}
`;

const Row = styled.div`
  padding: 0 0 20px;
  font-size: 1.125rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'flex-start'};
  margin-top: ${props => props.marginTop};
  ${media.large`
    padding: 10px 0;
  `}
`;

const NoGutterRow = styled(Row)`
  padding: 0 !important;
`

const ReversingDesktopRow = styled(Row)`
  ${media.large`
    flex-direction: row-reverse;
  `}
`

const RowCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  align-self: ${props => props.align || 'center'};
  ${media.large`
    margin-bottom: 0;
  `}
`;

const RowColHalf = styled(RowCol)`
  flex: 0 0 100%;
  ${media.large`
    flex: 0.5;
  `}
`;

const Row3Col1 = styled(RowCol)`
  flex: 0 0 100%;

  ${media.large`
    flex: 0.34;
  `}
`;

const Row3Col2 = styled(RowCol)`
  flex: 0 0 100%;

  ${media.large`
    flex: 0.66;
  `}
`;

const RatioRowCol = styled(RowCol)`
  flex: 0 0 100%;
  ${media.large`
    flex: ${props => props.ratio ? props.ratio : '1'};
  `}
`


const GridCol = styled(RowCol)`
  flex: ${props => props.rowCount ? `${1 / props.rowCount} 0 ${100 / props.rowCount}%` : '0.333333 0 33.3333%'};
  ${media.large`
    flex: ${props => props.rowCount ? `${0.9 / props.rowCount} 0 ${90 / props.rowCount}%` : '0.3 0 30%'};
  `}
`

const RowTextWrapper = styled(Row3Col2)`
  justify-content: center;
  align-items: flex-start;
`;

const RowGraphicWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 40px;
    ${media.large`
      margin-bottom: 0;
    `}
  }
  
  ${media.large`
    padding: ${props => props.padding ? props.padding : '0 30px'};
  `}
`;

const TextParagraph = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TextParagraphHeading = styled(TextParagraph)`
  font-weight: bold;
  text-transform: uppercase;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const Caption = styled.div`
  margin: 20px 0;
  font-weight: bold;
  text-align: center;
  ${media.large`
    margin: 0;
  `}
`

const Ul = styled.ul`
  margin-block-start: ${props => props.marginTop || 0};
  margin-block-end: 0;
  
  ${media.large`
    margin-block-start: 0;
  `}
`

const SuperEmphasis = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
`;

const CTAButton = styled.button`
  border-radius: 2px;
  border: solid 1px #1f2d54;
  padding: 0.75rem 64px;
  background-color: #1f2d54;
  font-size: 1.125rem;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
`;

const GridImageWrapper = styled(RowCol)`
  margin-left: -20px;
  margin-right: -20px;
  max-width: 1220px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  flex: 0 1 auto;
  align-self: center;
`;

const Image = styled.img`
  display: flex;
  align-self: center;
  max-width: 100%;
  height: auto;
  min-height: 0;
  margin-bottom: 40px;
  ${media.large`
    margin-bottom: ${props => props.marginBottom || 0}px;
  `}
`;

const BorderedImage = styled(Image)`
  border: solid 1px ${props => props.color ? props.color : '#979797'};
`

const OptionImage = styled.img`
  max-width: 100%;
  height: auto;
  min-height: 0;
  align-self: center;
`;

const PaddedImage = styled(OptionImage)`
  box-sizing: border-box;
  ${media.large`
    padding: 20px;
  `}
`

const ListElement = styled.li`
  margin-top: 1em;

  &:first-child {
    margin-top:0;
  }
`;

const LinkElement = styled(Link)`
  color: #1F2D54;
`;

const SectionIds = {
  measurePhysical: '#physical-section',
  measureDigital: '#digital-section',
  measureTouchless: '#touchless-section',
  measureLimitless: '#limitless-section',
  operationsCounter: '#counter-section',
  checkInAndOut: '#check-in-and-out',
  noiseSensor: '#noise-sensor',
};

const CloseButton = styled.img`
  width: 20px;
  cursor: pointer;
  margin-inline-start: 12px;
`;

export {
  SectionSubheading,
  Row,
  ReversingDesktopRow,
  NoGutterRow,
  RowCol,
  RowColHalf,
  Row3Col1,
  Row3Col2,
  RatioRowCol,
  GridCol,
  RowTextWrapper,
  RowGraphicWrapper,
  TextParagraph,
  TextParagraphHeading,
  BoldText,
  Caption,
  SuperEmphasis,
  CTAButton,
  GridImageWrapper,
  Image,
  OptionImage,
  PaddedImage,
  ListElement,
  Ul,
  LinkElement,
  SectionIds,
  CloseButton,
  BorderedImage
};