import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {media} from '../../styles/media-query-template';
import {langPrefix, passLanguage, useLanguage} from '../../components/language-context';
import { AnalyticsService, GACategories } from '../../services/analytics-service';
import {navigate} from 'gatsby';
import {Layout} from '../../components/layout';
import {Section, Subsection, MiddleSectionPadding} from '../../components/section';
import {smoothScrollTo} from '../../components/offering-common-components';
import {goToRequestForm} from '../../components/request-form';
import {
  BoldText,
  CTAButton,
  GridCol,
  Image,
  LinkElement,
  ListElement,
  OptionImage,
  PaddedImage,
  Row,
  ReversingDesktopRow,
  Row3Col1,
  Row3Col2,
  RowCol,
  RowColHalf,
  SectionSubheading,
  TextParagraph,
  TextParagraphHeading,
  Ul, BorderedImage, SectionIds
} from '../../components/platform-common-components';

import BannerTouchlessImg from '../../images/product-services/sentiment/measure_touchless.png';
import BannerSmileyBoxImg from '../../images/product-services/sentiment/measure_physical.png';
import BannerVSBImg from '../../images/product-services/sentiment/measure_digital.png';
import BannerDesignEdition from '../../images/product-services/sentiment/measure_design_edition.png';
import SmileyBoxImg from '../../images/product-services/sentiment/feedback-now-box.png';

import BadgeNew from '../../images/product-services/sentiment/sentiment-touchless-new-rosette.png';
import TouchlessExample1 from '../../images/product-services/sentiment/touchless-same-1.png';
import TouchlessVideo from '../../videos/fbn-touchless.mp4';

import PhysicalOption1 from '../../images/product-services/sentiment/sentiment-sb-option-1-wall-mount.png';
import PhysicalOption2 from '../../images/product-services/sentiment/sentiment-sb-option-2-voting-stand.png';
import PhysicalOption3 from '../../images/product-services/sentiment/sentiment-sb-option-3-counter-top.png';
import PhysicalOption4 from '../../images/product-services/sentiment/sentiment-sb-option-4-wall-mount.png';
import PhysicalOption5 from '../../images/product-services/sentiment/sentiment-sb-option-5-voting-stand.png';
import PhysicalOption6 from '../../images/product-services/sentiment/sentiment-sb-option-6-counter-top.png';

import TabletOption1 from '../../images/product-services/sentiment/sentiment-tablet-wall-mount.png';
import TabletOption2 from '../../images/product-services/sentiment/sentiment-tablet-voting-stand.png';
import TabletOption3 from '../../images/product-services/sentiment/sentiment-tablet-counter-top.png';

import EmbedOption1 from '../../images/product-services/sentiment/sentiment-digital-embed-1.png';
import EmbedOption2 from '../../images/product-services/sentiment/sentiment-digital-embed-2.png';
import EmbedOption3 from '../../images/product-services/sentiment/sentiment-digital-embed-3.png';

import LinksOption1 from '../../images/product-services/sentiment/sentiment-digital-links-1.png';
import LinksOption2 from '../../images/product-services/sentiment/sentiment-digital-links-2.png';
import LinksOption3 from '../../images/product-services/sentiment/sentiment-digital-links-3.png';
import LinksOption4 from '../../images/product-services/sentiment/sentiment-digital-links-4.png';
import LinksOption5 from '../../images/product-services/sentiment/sentiment-digital-links-5.png';

import QRCodeOption1 from '../../images/product-services/sentiment/sentiment-digital-qrcode-1.png';
import QRCodeOption2 from '../../images/product-services/sentiment/sentiment-digital-qrcode-2.png';
import QRCodeOption3 from '../../images/product-services/sentiment/sentiment-digital-qrcode-3.png';
import QRCodeOption4 from '../../images/product-services/sentiment/sentiment-digital-qrcode-4.png';
import QRCodeOption5 from '../../images/product-services/sentiment/sentiment-digital-qrcode-5.png';

import AnimatedVSB from '../../images/product-services/sentiment/animated-vsb.gif';



const AnimatedImg = styled(Image)`
  width: 297px;
`

const MeasureBanner = styled.div`
  display: flex;
  flex-direction: row;
`;

const MeasureMedium = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MeasureMediumImage = styled.img`
  margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
`;

const TouchlessNewRosette = styled.img`
  width: 25%;
  max-width: 70px;
  position: absolute;
  right: 21px;
  bottom: -5%;

  ${media.large`
    bottom: 0px;
  `}
`;

const MeasureMediumButton = styled.button`
  padding: 8px 32px;
  border-radius: 2px;
  border: solid 1px #1f2d54;
  background-color: #FFFFFF;
  font-size: 1rem;
  cursor: pointer;
  display: none;

  ${media.large`
    display: block;
  `}
`;

const ResponseRateCircle = styled.div`
  box-sizing: border-box;
  padding: 30px;
  width: 226px;
  height: 226px;
  border-radius: 50%;
  background-color: rgba(193, 243, 223, 0.5);
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

const ResponseRateText = styled.p`
  line-height: 1.3em;

  *[dir='rtl'] & {
    font-size: 1rem;
  }
`;

const ResponseRate = styled.p`
  font-size: 3.75em;

  *[dir='rtl'] & {
    line-height: 1em;
  }
`;

const SolutionsButtonsRow = styled.div`
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SolutionsButton = styled.button`
  margin: 20px;
  border-radius: 2px;
  border: solid 1px #1f2d54;
  padding: 0.5rem 1rem;
  background-color: ${props => props.status === 'selected' ? '#1f2d54' : '#FFFFFF'};
  max-width: 250px;
  font-size: 1rem;
  font-weight: bold;
  color: ${props => props.status === 'selected' ? '#FFFFFF' : '#666666'};
  flex: 1 0 130px;
  cursor: pointer;
`;

const RowVideoWrapper = styled(Row3Col2)`
  ${media.large`
    margin-inline-end: 30px;
  `}
`;

const VideoElement = styled.video`
  border: 1px solid #D8D8D8;
  width: 100%;
`;

const VSBIframe = styled.iframe`
  border: none;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  width: 100%;
  height: 675px;
  position: absolute;
  left: 0px;

  ${media.large`
    height: 450px;
  `}
`;

const VSBIframeSpacer = styled.div`
  width: 100%;
  height: 675px;

  ${media.large`
    height: 450px;
  `}
`;

const VSBLinks = {
  ar: '51ForAE7282C',
  de: '51For8E17AF3',
  en: '51ForB95ECF7',
  es: '51For1686754',
  fr: '51For068818E',
  it: '51ForF1CD4D3',
  pt: '51For053F35A'
};

const TabletSolution = (msg) => {
  return (
    <>
      <Row>
        <RowCol>
          <TextParagraphHeading>{msg('platform-measure-sentiment-digital-tablets')}</TextParagraphHeading>
          <TextParagraph>
            {msg('platform-measure-sentiment-digital-tablet-text')}
          </TextParagraph>
        </RowCol>
      </Row>
      <Row justify='space-between'>
        <GridCol>
          <Image src={TabletOption1}/>
        </GridCol>
        <GridCol>
          <Image src={TabletOption2}/>
        </GridCol>
        <GridCol>
          <Image src={TabletOption3}/>
        </GridCol>
      </Row>
    </>
  );
};

const EmbedSolution = (msg) => {
  return (
    <>
      <Row>
        <RowCol>
          <TextParagraphHeading>{msg('platform-measure-sentiment-digital-embed')}</TextParagraphHeading>
          <TextParagraph>
            {msg('platform-measure-sentiment-digital-embed-text-1')}
          </TextParagraph>
          <TextParagraph>
            <BoldText>{msg('platform-measure-sentiment-digital-embed-text-2-heading')}</BoldText> {msg('platform-measure-sentiment-digital-embed-text-2')}
          </TextParagraph>
          <TextParagraph>
            <BoldText>{msg('platform-measure-sentiment-digital-embed-text-3-heading')}</BoldText> {msg('platform-measure-sentiment-digital-embed-text-3')}
          </TextParagraph>
          <TextParagraph>
            <BoldText>{msg('platform-measure-sentiment-digital-embed-text-4-heading')}</BoldText> {msg('platform-measure-sentiment-digital-embed-text-4')}
          </TextParagraph>
        </RowCol>
      </Row>
      <Row justify='space-between'>
        <GridCol>
          <Image src={EmbedOption1}/>
        </GridCol>
        <GridCol>
          <Image src={EmbedOption2}/>
        </GridCol>
        <GridCol>
          <Image src={EmbedOption3}/>
        </GridCol>
      </Row>
    </>
  );
};

const LinksSolution = (msg) => {
  return (
    <>
      <Row>
        <RowCol>
          <TextParagraphHeading>{msg('platform-measure-sentiment-digital-links')}</TextParagraphHeading>
          <TextParagraph>
            {msg('platform-measure-sentiment-digital-links-text-1')}
          </TextParagraph>
          <TextParagraph>
            <BoldText>{msg('platform-measure-sentiment-digital-links-text-2-heading')}</BoldText> {msg('platform-measure-sentiment-digital-links-text-2')}
          </TextParagraph>
          <TextParagraph>
            <BoldText>{msg('platform-measure-sentiment-digital-links-text-3-heading')}</BoldText> {msg('platform-measure-sentiment-digital-links-text-3')}
          </TextParagraph>
        </RowCol>
      </Row>
      <Row justify='space-between'>
        <GridCol rowCount={5}>
          <BorderedImage src={LinksOption1}/>
        </GridCol>
        <GridCol rowCount={5}>
          <BorderedImage src={LinksOption2}/>
        </GridCol>
        <GridCol rowCount={5}>
          <BorderedImage src={LinksOption3}/>
        </GridCol>
        <GridCol rowCount={5}>
          <BorderedImage src={LinksOption4}/>
        </GridCol>
        <GridCol rowCount={5}>
          <BorderedImage src={LinksOption5}/>
        </GridCol>
      </Row>
    </>
  );
};

const QRCodeSolution = (msg) => {
  return (
    <>
      <Row>
        <RowCol>
          <TextParagraphHeading>{msg('platform-measure-sentiment-digital-qrcode')}</TextParagraphHeading>
          <TextParagraph>
            {msg('platform-measure-sentiment-digital-qrcode-text-1')}
          </TextParagraph>
          <TextParagraph>
            <BoldText>{msg('platform-measure-sentiment-digital-qrcode-text-2-heading')}</BoldText> {msg('platform-measure-sentiment-digital-qrcode-text-2')}
          </TextParagraph>
          <TextParagraph>
            <BoldText>{msg('platform-measure-sentiment-digital-qrcode-text-3-heading')}</BoldText> {msg('platform-measure-sentiment-digital-qrcode-text-3')}
          </TextParagraph>
          <TextParagraph>
            <BoldText>{msg('platform-measure-sentiment-digital-qrcode-text-4-heading')}</BoldText> {msg('platform-measure-sentiment-digital-qrcode-text-4')}
          </TextParagraph>
        </RowCol>
      </Row>
      <Row justify='space-between'>
        <GridCol rowCount={4}>
          <BorderedImage src={QRCodeOption1}/>
        </GridCol>
        <GridCol rowCount={5.5}>
          <BorderedImage src={QRCodeOption2}/>
        </GridCol>
        <GridCol rowCount={5.5}>
          <BorderedImage src={QRCodeOption3}/>
        </GridCol>
        <GridCol rowCount={5.5}>
          <BorderedImage src={QRCodeOption4}/>
        </GridCol>
        <GridCol rowCount={5.5}>
          <BorderedImage src={QRCodeOption5}/>
        </GridCol>
      </Row>
    </>
  );
};

const SentimentPageBase = useLanguage(({location, lang, msg, scrollTo}) => {

  const [needScroll, setNeedScroll] = useState(true);

  useEffect(() => {
    if (!scrollTo || !needScroll) return;
    smoothScrollTo(scrollTo);
  });
  const DigitalSolutionTypes = [
    'tablets',
    'embed',
    'links',
    'qrcode'
  ];
  const [digitalSolution, setDigitalSolution] = useState(DigitalSolutionTypes[0]);

  const DigitalSolutions = {
    tablets: {
      label: msg('platform-measure-sentiment-digital-tablets'),
      component: TabletSolution
    },
    embed: {
      label: msg('platform-measure-sentiment-digital-embed'),
      component: EmbedSolution
    },
    links: {
      label: msg('platform-measure-sentiment-digital-links'),
      component: LinksSolution,
    },
    qrcode: {
      label: msg('platform-measure-sentiment-digital-qrcode'),
      component: QRCodeSolution
    }
  }

  const updateDigitalSolution = (event) => {
    setNeedScroll(false);
    setDigitalSolution(event.target.value);
    AnalyticsService.trackCustomEvent(event);
  };

  const goToTouchless = () => {
    navigate(langPrefix(lang, `/product-services/touchless`));
  };

  const goToPhysical = () => {
    navigate(langPrefix(lang, `/product-services/physical`));
  };

  const goToDigital = () => {
    navigate(langPrefix(lang, `/product-services/digital`));
  };

  const goToAnalyze = () => {
    navigate(langPrefix(lang, `/product-services/analyze`));
  }

  const goToDesignEdition = () => {
    navigate("https://explore.feedbacknow.com/design-edition-cx/");
  }

  return (
    <Layout location={location}>
      <MeasureBanner>
        <MeasureMedium>
          <MeasureMediumImage src={BannerTouchlessImg} onClick={goToTouchless}/>
          <MeasureMediumButton onClick={goToTouchless}>{msg('platform-measure-sentiment-touchless')}</MeasureMediumButton>
        </MeasureMedium>
        <MeasureMedium>
          <MeasureMediumImage src={BannerSmileyBoxImg} onClick={goToPhysical}/>
          <MeasureMediumButton onClick={goToPhysical}>{msg('platform-measure-sentiment-physical')}</MeasureMediumButton>
        </MeasureMedium>
        <MeasureMedium>
          <MeasureMediumImage src={BannerVSBImg} onClick={goToDigital}/>
          <MeasureMediumButton onClick={goToDigital}>{msg('platform-measure-sentiment-digital')}</MeasureMediumButton>
        </MeasureMedium>
        <MeasureMedium>
          <MeasureMediumImage src={BannerDesignEdition} onClick={goToDigital}/>
          <TouchlessNewRosette src={BadgeNew} />
          <MeasureMediumButton onClick={goToDesignEdition}>{msg('platform-measure-sentiment-design-edition')}</MeasureMediumButton>
        </MeasureMedium>
      </MeasureBanner>
      <Section title={msg('platform-measure-sentiment-title')} lineWidth={100} titleWidth='100%' lang={lang}>
        <SectionSubheading>{msg('platform-measure-sentiment-subtitle')}</SectionSubheading>
      </Section>
      <Subsection title={msg('platform-measure-sentiment-touchless-1-title')}
               headerTitleLabel={msg('platform-measure-sentiment-touchless-1-title-label')} lineWidth={0}
               titleWidth='100%' lang={lang} showLine={true} keepLine={true} id='touchless-section'
               padding={MiddleSectionPadding}>
        <Row>
          <RowVideoWrapper>
            <VideoElement autoPlay='true' muted='true' playsInline='true' loop>
              <source src={TouchlessVideo} type='video/mp4' />
            </VideoElement>
          </RowVideoWrapper>
          <Row3Col1>
            <TextParagraph>{msg('platform-measure-sentiment-touchless-1-text-1')}</TextParagraph>
            <TextParagraph>
              {msg('platform-measure-sentiment-touchless-1-text-2')}&nbsp;
              <BoldText>{msg('platform-measure-sentiment-touchless-1-text-2-heavy')}</BoldText>
            </TextParagraph>
          </Row3Col1>
        </Row>
      </Subsection>
      <Subsection title={msg('platform-measure-sentiment-touchless-2-title')} lineWidth={0}
                  titleWidth='100%' lang={lang}
                  padding={MiddleSectionPadding}>
        <Row>
          <RowCol>
            <ul>
              <ListElement>{msg('platform-measure-sentiment-touchless-2-text-1')}</ListElement>
              <ListElement>{msg('platform-measure-sentiment-touchless-2-text-2')}</ListElement>
              <ListElement>{msg('platform-measure-sentiment-touchless-2-text-3')}</ListElement>
              <ListElement>{msg('platform-measure-sentiment-touchless-2-text-4')}</ListElement>
            </ul>
          </RowCol>
        </Row>
      </Subsection>
      <Subsection title={msg('platform-measure-sentiment-touchless-3-title')} lineWidth={0} titleWidth='100%'
               lang={lang}>
        <Row>
          <RowColHalf>
            <Ul>
              <ListElement>{msg('platform-measure-sentiment-touchless-3-text-1')} <LinkElement
                to={langPrefix(lang, '/product-services/physical')}>{msg('platform-measure-sentiment-touchless-3-text-1-sblink')}</LinkElement></ListElement>
              <ListElement>{msg('platform-measure-sentiment-touchless-3-text-2')}</ListElement>
              <ListElement>{msg('platform-measure-sentiment-touchless-3-text-3')}</ListElement>
              <ListElement>{msg('platform-measure-sentiment-touchless-3-text-4')}</ListElement>
              <ListElement>{msg('platform-measure-sentiment-touchless-3-text-5')}</ListElement>
              <ListElement>{msg('platform-measure-sentiment-touchless-3-text-6')}</ListElement>
            </Ul>
          </RowColHalf>
          <RowColHalf>
            <OptionImage src={TouchlessExample1}/>
          </RowColHalf>
        </Row>
        <Row>
          <RowCol>
            <CTAButton onClick={(event) => goToRequestForm(event, lang, '/product-services/touchless')}>
              {msg('contact-us')}
            </CTAButton>
          </RowCol>
        </Row>
      </Subsection>
      <Subsection title={msg('platform-measure-sentiment-physical-1-title')}
               headerTitleLabel={msg('platform-measure-sentiment-physical')} lineWidth={0} titleWidth='100%'
               lang={lang} showLine={true} keepLine={true} id='physical-section' padding={MiddleSectionPadding}>
        <Row>
          <Row3Col2>
            <TextParagraph>
              {msg('platform-measure-sentiment-physical-1-text-1')}
            </TextParagraph>
          </Row3Col2>
          <Row3Col1>
            <ResponseRateCircle>
              <ResponseRateText>{msg('platform-measure-sentiment-physical-response-rate-1')}</ResponseRateText>
              <ResponseRate>{msg('platform-measure-sentiment-physical-response-rate-2')}</ResponseRate>
            </ResponseRateCircle>
          </Row3Col1>
        </Row>
        <ReversingDesktopRow>
          <Row3Col2>
            <TextParagraph>
              <BoldText>{msg('platform-measure-sentiment-physical-1-text-2-heading')}</BoldText> {msg('platform-measure-sentiment-physical-1-text-2')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-sentiment-physical-1-text-3-heading')}</BoldText> {msg('platform-measure-sentiment-physical-1-text-3')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-sentiment-physical-1-text-4-heading')}</BoldText> {msg('platform-measure-sentiment-physical-1-text-4')}
            </TextParagraph>
          </Row3Col2>
          <Row3Col1>
            <PaddedImage src={SmileyBoxImg}/>
          </Row3Col1>
        </ReversingDesktopRow>
        <Row>
          <Row3Col1>
          </Row3Col1>
          <Row3Col2>
            <div>
              <CTAButton onClick={(event) => goToRequestForm(event, lang, '/product-services/physical')}>
                {msg('platform-quote')}
              </CTAButton>
            </div>
          </Row3Col2>
        </Row>
      </Subsection>
      <Subsection title={msg('platform-measure-sentiment-physical-2-title')} lineWidth={0} titleWidth='100%' lang={lang}
               padding={MiddleSectionPadding} id={SectionIds.measureLimitless.slice(1)}>
        <Row>
          <RowCol>
            <TextParagraph>{msg('platform-measure-sentiment-physical-2-text-1')}</TextParagraph>
          </RowCol>
        </Row>
        <Row justify='center'>
          <GridCol rowCount={3}>
            <Image marginBottom={20} src={PhysicalOption1}/>
          </GridCol>
          <GridCol rowCount={3}>
            <Image marginBottom={20} src={PhysicalOption2}/>
          </GridCol>
          <GridCol rowCount={3}>
            <Image marginBottom={20} src={PhysicalOption3}/>
          </GridCol>
          <GridCol rowCount={3}>
            <Image marginBottom={20} src={PhysicalOption4}/>
          </GridCol>
          <GridCol rowCount={3}>
            <Image marginBottom={20} src={PhysicalOption5}/>
          </GridCol>
          <GridCol rowCount={3}>
            <Image marginBottom={20} src={PhysicalOption6}/>
          </GridCol>
        </Row>
      </Subsection>
      <Subsection title={msg('platform-measure-sentiment-physical-3-title')} lineWidth={0} titleWidth='100%' lang={lang}>
        <Row>
          <RowCol>
            <TextParagraph>
              <BoldText>{msg('platform-measure-sentiment-physical-3-text-1-heading')}</BoldText> {msg('platform-measure-sentiment-physical-3-text-1')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-sentiment-physical-3-text-2-heading')}</BoldText> {msg('platform-measure-sentiment-physical-3-text-2')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-sentiment-physical-3-text-3-heading')}</BoldText> {msg('platform-measure-sentiment-physical-3-text-3')}
            </TextParagraph>
            <TextParagraph>
              <CTAButton onClick={goToAnalyze}>{msg('platform-visualize-data')}</CTAButton>
            </TextParagraph>
          </RowCol>
        </Row>
      </Subsection>
      <Subsection title={msg('platform-measure-sentiment-digital-1-title')}
               headerTitleLabel={msg('platform-measure-sentiment-digital')} lineWidth={0} titleWidth='100%'
               lang={lang} showLine={true} keepLine={true} id='digital-section' padding={MiddleSectionPadding}>
        <Row>
          <Row3Col2>
            <TextParagraph>{msg('platform-measure-sentiment-digital-1-text-1')}</TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-sentiment-digital-1-text-2-heading')}</BoldText> {msg('platform-measure-sentiment-digital-1-text-2')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-sentiment-digital-1-text-3-heading')}</BoldText> {msg('platform-measure-sentiment-digital-1-text-3')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-sentiment-digital-1-text-4-heading')}</BoldText> {msg('platform-measure-sentiment-digital-1-text-4')}
            </TextParagraph>
          </Row3Col2>
          <Row3Col1>
            <AnimatedImg src={AnimatedVSB}/>
          </Row3Col1>
        </Row>
      </Subsection>
      <Subsection title={msg('platform-measure-sentiment-digital-2-title')} lineWidth={0} titleWidth='100%' lang={lang}>
        <SolutionsButtonsRow>
          {DigitalSolutionTypes.map((type, i) => {
            return (
              <SolutionsButton
                value={type}
                status={(type === digitalSolution) ? 'selected' : 'unselected'}
                onClick={updateDigitalSolution}
                data-ec={GACategories.PlatformMeasure}
                data-ea='Button Click'
                data-el={`Digital Solution ${type} button clicked`}
              >{DigitalSolutions[type].label}</SolutionsButton>
            );
          })}
        </SolutionsButtonsRow>
        {DigitalSolutions[digitalSolution].component(msg)}
        <Row>
          <RowCol>
            <TextParagraphHeading>{msg('platform-measure-sentiment-try')}</TextParagraphHeading>
          </RowCol>
        </Row>
        <Row>
          <VSBIframeSpacer/>
          <VSBIframe src={`https://vsb.feedbacknow.com/#/${VSBLinks[lang]}`} />
        </Row>
      </Subsection>
    </Layout>
  )
});

const SentimentPage = passLanguage(SentimentPageBase)

export default SentimentPage